<template>
  <div class="home">
    <h1>COMING SOON</h1>
    <h3>Our website is under construction</h3>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<style scoped>
.home{
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

</style>
